import React from 'react'

import { colors, margins, fontSizes, breakpoint } from '../../cms/style'
// import stringEnter from '../../utils/stringEnter.util'
import { section2 } from '../../cms/data/webclever/data'



export default function S2() {

  return (
    <section css={styles.mainSection}>
      <div css={styles.info}>
        {section2.paragraphs.map((el, index) => (
          <p key={index}>{el}</p>
        ))}
      </div>
    </section>
  )
}

const styles = {
  mainSection: {
    padding: margins.sectionsX.xl,

    [breakpoint.lg]: {
      padding: margins.sectionsX.lg,
    },
    [breakpoint.md]: {
      padding: margins.sectionsX.md,
    },
    [breakpoint.sm]: {
      padding: margins.sectionsX.sm,
    },
    [breakpoint.xs]: {
      padding: margins.sectionsX.xs,
    },
  },

  info: {
    p: {
      fontSize: "1.1rem",
    },
    showreel: {
      height: "30rem",
      border: "1px solid #000",
      margin: "3rem 0",
      [breakpoint.sm]: {
        width: "100%",
      },
    },

    [breakpoint.lg]: {
      p: {
        fontSize: fontSizes.fz4.lg,
      },
    },
    [breakpoint.md]: {
      width: "70%",

      p: {
        fontSize: fontSizes.fz4.md,
      },
    },
    [breakpoint.sm]: {
      width: "100%",

      p: {
        fontSize: fontSizes.fz4.sm,
      },
    },
    [breakpoint.xs]: {
      p: {
        fontSize: fontSizes.fz4.sm,
      },
    },
  },
}
