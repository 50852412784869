export const section2 = {
    paragraphs: [
        "To marka dzięki której zyskasz widoczność w sieci.",
        "Tworzymy strony internetowe i dostosowujemy ich funkcjonalności do potrzeb klientów.",
        "Od designu, aż po kod.",
        "Bez strony interenetowej nie dotrzesz do odpowiedniej liczby odbiorców, dlatego jeśli jeszcze jej nie masz to wypełnij formluarz i załącz brief.",
        "Przygotujemy dla Ciebie ofertę zwiększenia widoczności w sieci i nie tylko.",
        "Bądź sprytny!",
    ],
}
