import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { S1, S2, S3 } from "../sections/webclever"
import Footer from "../sections/s4.footer.section"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="RDB Group | Webclever" />
      <S1 />
      <S2 />
      <S3 />
      <Footer />
    </Layout>
  )
}

export default IndexPage
